import { BlockComponent } from "../../../framework/src/BlockComponent";
import { RouteComponentProps } from "react-router-dom";
// Customizable Area Start
// Customizable Area End

export interface Props extends RouteComponentProps {
  // Customizable Area Start
  navigation: any;
  id: string;
  history: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  // Customizable Area End
}

class AppController extends BlockComponent<Props, S, SS> {
  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    // Customizable Area End
  }
  // Customizable Area End
}

export default AppController;
