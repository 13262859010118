//@ts-nocheck
import React, { Component } from "react";
import { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import "./App.css";
import Loader from "../../studio-store-elearning-components/src/AppLoader/AppLoader.web";

const HomeWeb = lazy(() => import("../../Home/src/Home.web"));
const MyLearningDashboard = lazy(
  () => import("../../MyLearning/src/MyLearningDashboard.web")
);
const CourseListing = lazy(
  () => import("../../CourseListing/src/CourseListing.web")
);
const CourseDetail = lazy(
  () => import("../../CourseDetail/src/CourseDetail.web")
);
const CourseCompletedDetail = lazy(
  () => import("../../CourseDetail/src/CourseDetail.web")
);
const EmailAccountLogin = lazy(
  () => import("../../email-account-registration/src/EmailAccountLogin.web")
);
const ForgotPassword = lazy(
  () => import("../../forgot-password/src/ForgotPassword.web")
);
const ForgotPasswordOtpVerification = lazy(
  () => import("../../forgot-password/src/ForgotPasswordOtp.web")
);
const ChangePassword = lazy(
  () => import("../../forgot-password/src/ChangePassword.web")
);
const HelpCenterBlock = lazy(
  () => import("../../helpcenter/src/HelpCenterBlock.web")
);
const Profilebio = lazy(() => import("../../MyProfile/src/MyProfile.web"));
const Notifications = lazy(
  () => import("../../NotificationsBlock/src/Notifications.web")
);
const OTPConfirm = lazy(
  () => import("../../otp-input-confirmation/src/OTPConfirmation.web")
);
const AboutUs = lazy(() => import("../../AboutUs/src/AboutUsBlock.web"));
import ProtectedRoute from "./protectedRoute.web";
const Header = lazy(
  () => import("../../studio-store-elearning-components/src/AppHeader")
);
const Footer = lazy(
  () =>
    import("../../studio-store-elearning-components/src/AppFooter/Footer.web")
);
const CourseVideo = lazy(
  () => import("../../CourseVideo/src/CourseContent.web")
);
const ContactUs = lazy(() => import("../../contactus/src/ContactUs.web"));

class DebugRouter extends Router {
  constructor(props) {
    super(props);
    this.history.listen((location, action) => {});

    let STORE_BASE_PATH_NAME = "/";

    try {
      STORE_BASE_PATH_NAME =
        new URL(`${process.env.REACT_APP_BASE_URL}`).pathname ?? "";
    } catch {}
  }
}

class WebRoutes extends Component {
  render() {
    const { history } = this.props;
    const Appthem = JSON.parse(localStorage.getItem("appThemData") ?? "{}");

    let STORE_BASE_PATH_NAME = "/";

    try {
      STORE_BASE_PATH_NAME =
        new URL(`${process.env.REACT_APP_BASE_URL}`).pathname ?? "";
    } catch {}

    return (
      <Router history={history} basename={STORE_BASE_PATH_NAME}>
        <Suspense fallback={<Loader loading={true} />}>
          <Header />
          <div className="flex-grow-1" style={{ background: "#FAFAFA" }}>
            <Switch>
              <Route
                path="/"
                exact
                render={(props) => <HomeWeb {...props} />}
              />
              <Route
                path="/login"
                exact
                render={(props) => <EmailAccountLogin {...props} />}
              />
              <Route
                path="/course-list"
                exact
                render={(props) => <CourseListing {...props} />}
              />
              <Route
                path="/course-description/:courseId"
                exact
                render={(props) => <CourseDetail {...props} />}
              />
              <Route
                path="/course-completed-description/:courseId"
                exact
                render={(props) => <CourseCompletedDetail {...props} />}
              />
              <Route
                path="/forgot-password"
                exact
                render={(props) => <ForgotPassword {...props} />}
              />
              <Route
                path="/forgot-password-otp-confirmation"
                exact
                render={(props) => <ForgotPasswordOtpVerification {...props} />}
              />
              <Route
                path="/change-password"
                exact
                render={(props) => <ChangePassword {...props} />}
              />
              <Route
                path="/otp-confirmation"
                exact
                render={(props) => <OTPConfirm {...props} />}
              />
              {/* Protect Roues Start */}
              <ProtectedRoute path="/home-page" exact component={HomeWeb} />
              <Route
                path="/course-detail/:courseId"
                exact
                component={CourseVideo}
              />
              <Route path="/aboutus" exact component={AboutUs} />
              {/* Protect Route End */}

              {/* Profile Protect Route start */}
              <ProtectedRoute
                path="/profilebio/notifications"
                exact
                component={Notifications}
              />
              <ProtectedRoute path="/profilebio" exact component={Profilebio} />
              <ProtectedRoute
                path="/profilebio/my-learning"
                exact
                render={(props) => <MyLearningDashboard {...props} />}
              />
              {/* Profile Protect Route End */}

              {/* Global catch start */}
              <Route path="/help-center" exact component={HelpCenterBlock} />
              <Route
                path="/help-center/:slug"
                exact
                component={HelpCenterBlock}
              />
              <Route path="/contact-us" exact component={ContactUs} />
              <Route component={EmailAccountLogin} />
              {/* Global catch end */}
            </Switch>
          </div>
          <Footer history={history} basename={STORE_BASE_PATH_NAME} />
        </Suspense>
      </Router>
    );
  }
}

export default withRouter(WebRoutes);
