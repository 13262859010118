// App.js - WEB
import React, { Component } from 'react';
import AppController from './AppController.web';
import firebase from 'firebase';
import 'bootstrap/dist/css/bootstrap.min.css';
import './fontastic/styles.css';
import ScrollToTopButton from 'react-scroll-to-top';
import HomeScreen from '../../../components/src/HomeScreen';
import { ToastContainer } from 'react-toastify';
import WebRoutes from './WebRoutes';
import { withRouter } from "react-router-dom";
// import PhoneLogin from "../../PhoneLogin/src/PhoneLogin";
// import GoogleLogin14 from "../../GoogleLogin14/src/GoogleLogin14";
// import FacebookLogin from "../../FacebookLogin/src/FacebookLogin";
// import DiscountsOffers from "../../DiscountsOffers/src/DiscountsOffers";
// import RolesPermissions2 from "../../RolesPermissions2/src/RolesPermissions2";
// import ShippingChargeCalculator from "../../ShippingChargeCalculator/src/ShippingChargeCalculator";
// import CouponCodeGenerator from "../../CouponCodeGenerator/src/CouponCodeGenerator";
// import SplashScreen2 from "../../SplashScreen2/src/SplashScreen2";
// import AddressManagement from "../../AddressManagement/src/AddressManagement";
// import AdminConsole3 from "../../AdminConsole3/src/AdminConsole3";
// import Reviews from "../../Reviews/src/Reviews";
// import InvoiceBilling from "../../InvoiceBilling/src/InvoiceBilling";


const routeMap = {
  // PhoneLogin:{
  //  component:PhoneLogin,
  // path:"/PhoneLogin"},
  // GoogleLogin14:{
  //  component:GoogleLogin14,
  // path:"/GoogleLogin14"},
  // FacebookLogin:{
  //  component:FacebookLogin,
  // path:"/FacebookLogin"},
  // DiscountsOffers:{
  //  component:DiscountsOffers,
  // path:"/DiscountsOffers"},
  // RolesPermissions2:{
  //  component:RolesPermissions2,
  // path:"/RolesPermissions2"},
  // ShippingChargeCalculator:{
  //  component:ShippingChargeCalculator,
  // path:"/ShippingChargeCalculator"},
  // CouponCodeGenerator:{
  //  component:CouponCodeGenerator,
  // path:"/CouponCodeGenerator"},
  // SplashScreen2:{
  //  component:SplashScreen2,
  // path:"/SplashScreen2"},
  // AddressManagement:{
  //  component:AddressManagement,
  // path:"/AddressManagement"},
  // AdminConsole3:{
  //  component:AdminConsole3,
  // path:"/AdminConsole3"},
  // Reviews:{
  //  component:Reviews,
  // path:"/Reviews"},
  // InvoiceBilling:{
  //  component:InvoiceBilling,
  // path:"/InvoiceBilling"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends AppController {
  // async componentDidMount() {
  //   window.scrollTo(0, 0);
  //   this.setState({ loader: true });
  // }

  render() {
    return (
      <div className='d-flex flex-column h-100'>
        <WebRoutes />
        <ScrollToTopButton smooth top={200}/>
        <ToastContainer />
      </div>
    );
  }
}

export default withRouter(App);